// Dependencies
import React, { useEffect, useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

// Helpers, Context
import { Context as PlayerContext } from '../../context/playerContext'
import { formatTime, useComponentVisible } from '../../helpers/utils'

// Styles
import {
  playerNextSVG,
  playerPlaySVG,
  playerPauseSVG,
  playerVolumeSVG,
  playerPrevSVG,
  playlistSVG,
  playlistCloseSVG,
} from '../../assets/icons'
import { PlayerWrapper } from './Musicas.styled'

class PlayerPortal extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.modalRoot = document.getElementById('modal-root')
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

const PlayerControls = ({
  albums,
  index,
  compact = false,
  showMoreLabel,
  showLessLabel,
}) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false)
  // Getting state and methods from Context
  const {
    state: {
      tracks,
      playingState,
      volume,
      currentAlbumId,
      currentTrack,
      currentTrackId,
      currentTrackDuration,
      elapsedPct,
      elapsedTime,
    },
    setTracks,
    seekPosition,
    playTrack,
    pauseTrack,
    setVolume,
    playNext,
    playPrev,
  } = useContext(PlayerContext)

  useEffect(() => {
    document.body.style.overflow = isComponentVisible ? 'hidden' : 'unset'
  }, [isComponentVisible])

  const setCurrentTrack = (track) => {
    playTrack({
      currentAlbumId: track.albumId,
      currentTrack: track,
      currentTrackId: track.trackId,
    })
  }

  const prevTrack = () => {
    let prevId = currentTrackId - 1
    let prevAlbumId = currentAlbumId
    if (prevId < 0) {
      prevAlbumId = currentAlbumId - 1
      if (prevAlbumId < 0) {
        prevId = albums[albums.length - 1].tracks.length - 1
        prevAlbumId = albums.length - 1
      } else {
        prevId = albums[prevAlbumId].tracks.length - 1
      }
    }

    playPrev({
      currentAlbumId: prevAlbumId,
      currentTrack: albums[prevAlbumId].tracks[prevId],
      currentTrackId: prevId,
    })
  }

  const nextTrack = () => {
    let nextId = currentTrackId + 1
    let nextAlbumId = currentAlbumId
    if (nextId >= albums[currentAlbumId].tracks.length) {
      nextId = 0
      nextAlbumId = currentAlbumId + 1
      if (nextAlbumId >= albums.length) {
        nextAlbumId = 0
      }
    }

    playNext({
      currentAlbumId: nextAlbumId,
      currentTrack: albums[nextAlbumId].tracks[nextId],
      currentTrackId: nextId,
    })
  }

  const togglePlay = (albumId) => {
    if (playingState === 'stopped') {
      playTrack({
        currentAlbumId: albumId,
        currentTrack: albums[albumId].tracks[0].trackId,
        currentTrackId: albums[albumId].tracks[0].trackId,
      })
    } else {
      pauseTrack({
        currentAlbumId: albumId,
        currentTrack: currentTrack,
        currentTrackId: currentTrackId,
      })
    }
  }

  const setPosition = (event) => {
    const e = event.nativeEvent
    let clickX
    if (e.layerX || e.layerY) {
      clickX = e.layerX
    } else if (e.offsetX || e.offsetY) {
      clickX = e.offsetX
    } else if (
      e.originalEvent &&
      (e.originalEvent.layerX || e.originalEvent.layerY)
    ) {
      clickX = e.originalEvent.layerX
    }
    const totalWidth = e.target.clientWidth
    const position = (currentTrackDuration * clickX) / totalWidth

    seekPosition({
      currentAlbumId: currentAlbumId,
      currentTrackId: currentTrackId,
      position,
    })
  }

  const formatDate = (date) => {
    var d = ('0' + (date.getDate() + 1)).slice(-2)
    var m = ('0' + (date.getMonth() + 1)).slice(-2)
    var y = date.getFullYear()
    return `${d}/${m}/${y}`
  }

  return currentAlbumId > -1 ? (
    <PlayerPortal>
      <PlayerWrapper>
        <div className="album__player">
          {isComponentVisible && (
            <div className="album__player__playlist">
              <div ref={ref}>
                {albums.map((album, index) => {
                  return (
                    <div key={`${album.title}_${index}`}>
                      <div className="album">
                        <div className="album__image">
                          <Img fluid={album.image.fluid} />
                        </div>
                        <div className="album__title">
                          <h4>{album.title}</h4>
                          <div>
                            <small>{album.type}</small>
                            <small>
                              {formatDate(new Date(album.releaseDate))}
                            </small>
                          </div>
                        </div>
                      </div>
                      <ul key={`${album.title}_${index}`}>
                        {album.tracks &&
                          album.tracks.map((track, j) => {
                            return tracks &&
                              tracks[index] &&
                              tracks[index][j] ? (
                              <li
                                className={
                                  'track' +
                                  (index === currentAlbumId &&
                                  j === currentTrackId &&
                                  playingState === 'playing'
                                    ? ' active'
                                    : '')
                                }
                                key={`${album.title}_${index}_${j}`}
                              >
                                <button
                                  onClick={() =>
                                    index === currentAlbumId &&
                                    j === currentTrackId
                                      ? togglePlay(currentAlbumId)
                                      : setCurrentTrack(tracks[index][j])
                                  }
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        index === currentAlbumId &&
                                        j === currentTrackId &&
                                        playingState === 'playing'
                                          ? playerPauseSVG
                                          : playerPlaySVG,
                                    }}
                                  />
                                  {/* <div className="album__image">
                                  <Img fluid={album.image.fluid} />
                                </div> */}
                                  <div className="track__title">
                                    {track.title} <small>{track.author}</small>
                                  </div>
                                  <div className="track__duration">
                                    {track.duration}
                                  </div>
                                </button>
                              </li>
                            ) : null
                          })}
                      </ul>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          <div className="album__player__interface">
            <div className="track">
              {currentTrack && (
                <>
                  <strong>{currentTrack.title}</strong>
                  {currentTrackDuration && elapsedTime && (
                    <small>{`${formatTime(elapsedTime) || '0:00'} / ${
                      formatTime(currentTrackDuration) || '0:00'
                    }`}</small>
                  )}
                </>
              )}
            </div>
            <div className="controls">
              <button
                onClick={() => prevTrack()}
                className="prev"
                dangerouslySetInnerHTML={{
                  __html: playerPrevSVG,
                }}
              />
              <button
                onClick={() => togglePlay(currentAlbumId)}
                className={`play${playingState === 'playing' ? ' active' : ''}`}
                dangerouslySetInnerHTML={{
                  __html:
                    playingState === 'playing' ? playerPauseSVG : playerPlaySVG,
                }}
              />
              <button
                onClick={() => nextTrack()}
                className="next"
                dangerouslySetInnerHTML={{
                  __html: playerNextSVG,
                }}
              />
              {!isComponentVisible && (
                <button
                  onClick={() => {
                    setIsComponentVisible(!isComponentVisible)
                  }}
                  className={`playlist${isComponentVisible ? ' active' : ''}`}
                  dangerouslySetInnerHTML={{
                    __html: playlistSVG,
                  }}
                />
              )}
              {isComponentVisible && (
                <button
                  onClick={() => {
                    setIsComponentVisible(false)
                  }}
                  className={`playlist${isComponentVisible ? ' active' : ''}`}
                  dangerouslySetInnerHTML={{
                    __html: playlistCloseSVG,
                  }}
                />
              )}
            </div>
          </div>
          {elapsedPct && (
            <div className="bar" onClick={setPosition}>
              <span
                className="fill"
                style={{
                  width: elapsedPct + '%',
                }}
              ></span>
            </div>
          )}
        </div>
      </PlayerWrapper>
    </PlayerPortal>
  ) : null
}

// Components PropTypes
const albumPropShape = PropTypes.shape({
  title: PropTypes.string,
  introCopy: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  year: PropTypes.string,
  type: PropTypes.string,
  spotifyUrl: PropTypes.string,
  soundcloudUrl: PropTypes.string,
  googleUrl: PropTypes.string,
  deezerUrl: PropTypes.string,
  appleUrl: PropTypes.string,
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      author: PropTypes.string,
      duration: PropTypes.string,
      spotifyUrl: PropTypes.string,
      soundcloudUrl: PropTypes.string,
      googleUrl: PropTypes.string,
      deezerUrl: PropTypes.string,
      appleUrl: PropTypes.string,
    })
  ),
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcWebp: PropTypes.string,
      srcSetWebp: PropTypes.string,
      sizes: PropTypes.string,
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
    }),
  }),
})
PlayerControls.propTypes = {
  albums: PropTypes.arrayOf(albumPropShape),
  album: albumPropShape,
  compact: PropTypes.bool,
}

export default PlayerControls
