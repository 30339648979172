// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Styles
import { SocialWrapper } from './Social.styled.js'

// Components
import {
  facebookSVG,
  instagramSVG,
  soundcloudSVG,
  emailSVG,
  spotifySVG,
  youtubeSVG,
  deezerSVG,
  pandoraSVG,
  itunesSVG,
  applemusicSVG,
  amazonSVG,
  whatsappSVG,
} from '../../assets/social'

const Social = ({ links }) => {
  return (
    <SocialWrapper>
      <div className="social-content">
        {links.map((link) => {
          let component
          switch (link?.type) {
            case 'facebook':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={link?.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: facebookSVG }}
                />
              )
              break
            case 'instagram':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={link?.type}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: instagramSVG }}
                />
              )
              break
            case 'youtube':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={link?.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: youtubeSVG }}
                />
              )
              break
            case 'soundcloud':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={link?.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: soundcloudSVG }}
                />
              )
              break
            case 'spotify':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={link?.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: spotifySVG }}
                />
              )
              break
            case 'deezer':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={link?.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: deezerSVG }}
                />
              )
              break
            case 'amazon':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={link?.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: amazonSVG }}
                />
              )
              break
            case 'pandora':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={link?.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: pandoraSVG }}
                />
              )
              break
            case 'itunes':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={link?.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: itunesSVG }}
                />
              )
              break
            case 'applemusic':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={link?.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: applemusicSVG }}
                />
              )
              break
            case 'email':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={`mailto:${link?.url}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: emailSVG }}
                />
              )
              break
            case 'whatsapp':
              component = (
                <a
                  key={`${link?.type}_${link?.url}`}
                  href={`https://api.whatsapp.com/send?phone=${link?.url}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  dangerouslySetInnerHTML={{ __html: whatsappSVG }}
                />
              )
              break
          }
          return component
        })}
      </div>
    </SocialWrapper>
  )
}

// Components PropTypes
Social.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Social
