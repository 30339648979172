// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const getMeta = ({
  site_title,
  site_description,
  keywords,
  twitter_handler,
  share_image,
  site_url,
}) => [
  { content: site_title, name: 'author' },
  { content: site_description, name: 'description' },
  {
    name: 'keywords',
    content: keywords,
  },

  { content: 'summary_large_image', name: 'twitter:card' },
  { content: twitter_handler, name: 'twitter:site' },
  { content: site_title, name: 'twitter:title' },
  { content: site_description, name: 'twitter:description' },
  { content: twitter_handler, name: 'twitter:creator' },
  {
    content: share_image?.file?.url ? share_image?.file?.url : '',
    name: 'twitter:image:src',
  },
  { content: site_url, name: 'twitter:domain' },

  { content: site_url, property: 'og:url' },
  { content: 'website', property: 'og:type' },
  { content: site_title, property: 'og:title' },
  {
    content: share_image?.file?.url ? share_image?.file?.url : '',
    property: 'og:image',
  },
  { content: site_description, property: 'og:description' },
  { content: site_title, property: 'og:site_name' },
  { content: 'en_GB', property: 'og:locale' },

  { content: site_title, itemprop: 'name' },
  { content: site_description, itemprop: 'description' },
  {
    content: share_image?.file?.url ? share_image?.file?.url : '',
    itemprop: 'image',
  },
]

const getLink = ({ site_url, favicon_16x16, favicon_32x32 }) => [
  { rel: 'canonical', href: site_url },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: favicon_16x16?.file?.url ? `${favicon_16x16?.file?.url}` : '',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: favicon_32x32?.file?.url ? `${favicon_32x32?.file?.url}` : '',
  },
  {
    rel: 'shortcut icon',
    type: 'image/png',
    href: favicon_32x32?.file?.url ? `${favicon_32x32?.file?.url}` : '',
  },
]

const HelmetEl = ({
  siteTitle: site_title,
  description: site_description,
  keywords,
  twitterHandler: twitter_handler,
  shareImage: share_image,
  siteUrl: site_url,
  favicon16x16: favicon_16x16,
  favicon32x32: favicon_32x32,
}) => {
  return (
    <Helmet>
      <title>{`${site_title}`}</title>
      {getMeta({
        site_title,
        site_description,
        keywords,
        twitter_handler,
        share_image,
        site_url,
      })?.map((tag) => (
        <meta key={tag.name || tag.property || tag.itemprop} {...tag} />
      ))}
      {getLink({
        site_url,
        favicon_16x16,
        favicon_32x32,
      })?.map((tag) => (
        <link key={tag.rel} {...tag} />
      ))}
    </Helmet>
  )
}

// Components PropTypes
HelmetEl.propTypes = {
  siteTitle: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  twitterHandler: PropTypes.string,
  shareImage: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  siteUrl: PropTypes.string,
  favicon16x16: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  favicon32x32: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
}
HelmetEl.defaultProps = {
  siteTitle: '',
  description: '',
  keywords: '',
  twitterHandler: '',
  shareImage: { file: { url: '' } },
  siteUrl: '',
  favicon16x16: { file: { url: '' } },
  favicon32x32: { file: { url: '' } },
}

export default HelmetEl
