// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Styles
import '../../assets/index.css'
import { Main, Grid, GridContent, Line, Content } from './Template.styled'

class Template extends React.Component {
  render() {
    const { children } = this.props

    const grid = 6
    return (
      <Main>
        <Grid grid={grid}>
          <GridContent>
            {Array(grid + 1)
              .fill('')
              .map((line, index) => (
                <Line key={`grid-line_${index}`} className="grid-line" />
              ))}
          </GridContent>
        </Grid>
        <Content>{children}</Content>
      </Main>
    )
  }
}

// Components PropTypes
Template.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
}

export default Template
