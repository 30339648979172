// Dependencies
import styled from 'styled-components'

// Constants
import { colors, creamGradient } from '../../constants/colors'
import { transition } from '../../constants/general'
import { playfairDisplay, type14 } from '../../constants/fonts'
import { sizesMin, sizesMax } from '../../constants/breakpoints'

export const LogoWrapper = styled.div`
  .content {
    background: ${colors.cream};
    min-width: calc(100% / 24);
    cursor: pointer;
    ${transition}

    &:hover {
      background: ${colors.white};
    }
  }

  position: relative;
  z-index: 1;
  height: 70px;
  left: 0;

  .content {
    display: table;
    height: 70px;
    width: 170px;

    svg {
      height: 70px;
      width: 100%;
      padding: 10px 30px;
    }
  }

  h1 {
    font-size: 0;
    color: transparent;
  }

  svg {
    max-width: 300px;
    height: auto;
    position: relative;
    display: block;

    .cravelhas,
    .espelho,
    .estandarte {
      .a {
        fill: #231f20;
      }
    }
    .corpo,
    .voluta {
      .b {
        fill: #fdcc9a;
        fill: #ffc551;
      }
    }
    .cabeca {
      .c {
        fill: #eaae7d;
        fill: #bf9033;
      }
    }
    .cavalhete {
      .d {
        fill: none;
        stroke-miterlimit: 10;
      }
      .d {
        stroke: #bf9033;
      }
    }
    .cordas {
      .f {
        fill: none;
        stroke-miterlimit: 10;
      }
      .e {
        fill: #f7941d;
      }
      .f {
        stroke: #e6e7e8;
        stroke-width: 0.25px;
      }
    }
    .fernando,
    .marinho {
      .g {
        fill: ${colors.black};
      }
    }
    .arco {
      .h {
        fill: #fdcd9a;
        fill: #bf9033;
      }
      .i {
        fill: #e7e9ed;
      }
    }
  }
`
