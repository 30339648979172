// Dependencies
import { useContext } from 'react'

// Helpers, Context, Components and Styles
import { Context as MicrocopyContext } from './microcopyContext'

export default () => {
  // Getting state and methods from Context
  const { state, setMicrocopy } = useContext(MicrocopyContext)

  return {
    state,
    setMicrocopy,
  }
}
