// Dependencies
import styled from 'styled-components'

// Helpers
import { hexToRgb } from '../../helpers/utils'

// Constants
import { colors, creamGradient } from '../../constants/colors'
import { maxWidth, transition } from '../../constants/general'
import {
  workSans,
  type50,
  type14,
  playfairDisplay,
  type18,
  type40,
  type16,
  type30,
  type25,
  type20,
} from '../../constants/fonts'
import { sizesMax } from '../../constants/breakpoints'

export const Main = styled.div`
  background: ${colors.dark};
`

export const Content = styled.div`
  ${maxWidth}
  z-index: 2;
  padding-bottom: 50px;
`

const sectionStyles = `
  h3 {
    ${playfairDisplay}
    font-style: italic;
    ${type30}
    font-weight: 900;
    line-height: 1.2;
    color: ${colors.cream};
    margin-bottom: 20px;
    display: table;
    // text-decoration: underline;
  }

  h4 {
    ${playfairDisplay}
    // font-style: italic;
    font-weight: normal;
    ${type20}
    line-height: 1.2;
    color: ${colors.white};
    margin-top: 0;
    margin-bottom: 15px;
    display: table;
    text-decoration: underline;
  }


  p {
    display: block;
    ${type18}
    line-height: 1.3;
    display: block;
    color: ${colors.white};
    margin: 0 0 20px;
  }

  em {
    font-style: italic;
    font-weight: bold;
  }

  .section-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;    

    > div {
      width: calc(100%);
    }

    h1, h2, h3, h4, h5, h6 {
      ${playfairDisplay}
      // font-style: italic;
      font-weight: normal;
      ${type20}
      line-height: 1.2;
      color: ${colors.white};
      margin-top: 0;
      margin-bottom: 15px;
      display: table;
      text-decoration: underline;
    }
  }

  .link-in-text {
    display: block;
    width: 100%;
    ${type14}
    ${workSans}
    line-height: 1;
    font-weight: bold;
    color: ${colors.cream};
    margin-bottom: 30px;
    cursor: pointer;
    ${transition}

    &:hover {
      color: ${colors.white};
    }
  }

  .social-nav {
    background: ${colors.cream};
    display: table;
    margin: 0 0 20px;
    margin: 0;

    &--page {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      @media screen and (max-width: ${sizesMax.laptop}) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  a.button,
  button.button {
    display: block;
    min-width: 300px;
    height: 50px;
    ${type18}
    ${workSans}
    line-height: 1.3;
    padding: 0 20px;
    margin: 20px auto 30px;
    background: rgba(${hexToRgb(colors.cream)}, 1);
    outline: none;
    color: ${colors.dark};
    border: none;
    cursor: pointer;
    font-weight: bold;
    position: relative;
    border: solid 1px ${colors.cream};
    ${transition}

    svg {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      right: 5px;
      margin-top: -20px;
      * {
        fill: ${colors.dark};
      }
    }

    span {
      z-index: 10;
      display: block;
      position: relative;
    }

    &:hover {
      background: rgba(${hexToRgb(colors.white)}, 1);
      border: solid 1px ${colors.white};
      color: ${colors.black};

      svg * {
        fill: ${colors.black};
      }
    }

    &:hover:after {
      opacity: 1;
    }
  }
  form button {
    color: ${colors.dark};
  }
  a.button {
    text-decoration: none;
    display: flex;
    width: 40%;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;

    // background: transparent;
    // border: solid 1px ${colors.cream};
    // color: ${colors.cream};
  }
  @media screen and (max-width: ${sizesMax.laptop}) {
    a.button,
    button.button {
      width: 100%;
    }
  }
`

export const SectionGrid = styled.section`
  .content {
    margin-top: 40px;
    // margin-left: calc(100% / 24);
    width: 100%;
  }

  ${sectionStyles}

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: ${(props) =>
    props.noPadding ? '0' : '50px 40px 50px'};
  padding-top: ${(props) => (props.noVerticalPadding ? '0' : '50px')};
  padding-bottom: ${(props) => (props.noVerticalPadding ? '0' : '50px')};

  @media screen and (max-width: ${sizesMax.laptop}) {
    padding: 0 20px;
  }

  .col-1-1 {
    width: 100%;

    .content {
      // margin-left: calc(100% / 48);
      width: 100%;
    }
    h2 {
      // margin-left: calc(100% / 48 + 50px);
    }
    @media screen and (max-width: ${sizesMax.laptop}) {
      .content {
        // margin-left: calc(100% / 24);
      }
      h2 {
        // margin-left: calc(100% / 24 + 50px);
      }
    }

    &.noTopPadding {
      padding-top: 0 !important;
    }
  }

  .col-1-2 {
    width: calc(50% - 40px);

    @media screen and (max-width: ${sizesMax.laptop}) {
      width: 100%;
    }
  }

  .col-1-3 {
    width: calc(100% / 3 - 40px);
  }

  .col-2-3 {
    width: calc(100% / 3 * 2 - 40px);
  }

  .col-1-1,
  .col-1-2,
  .col-1-3,
  .col-2-3 {
    padding: 0px 0;
    @media screen and (max-width: ${sizesMax.laptop}) {
      padding: 50px 0 10px;
      padding-top: ${(props) => (props.noVerticalPadding ? '0' : '50px')};
      padding-bottom: ${(props) => (props.noVerticalPadding ? '0' : '10px')};
    }
  }

  .flex {
    display: flex;
  }

  .flex-row {
    flex-direction: row;
  }

  .justify-between {
    justify-content: space-between;
  }
`

export const Section = styled.section`
  padding: 100px 40px;

  @media screen and (max-width: ${sizesMax.laptop}) {
    padding: 50px 20px;
  }

  .content {
    margin-top: 40px;
    // margin-left: calc(100% / 24);
    width: 100%;
  }
  ${sectionStyles}
`

export const SectionDivider = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  &:after {
    content: '';
    background: none;
    max-width: 100%;
    display: block;
    position: relative;
    border: 0;
    text-align: center;
    margin: -60px 0 60px;
    padding: 30px 0;
    box-shadow: 0 20px 20px -20px rgba(${hexToRgb(colors.black)}, .25);

    @media screen and (max-width: ${sizesMax.laptop}) {
      margin: -60px 0 30px;
      padding: 30px 0;
    }
  }

  
    // height: 20px;
    // position: relative;
    // width: 100%;
    // margin-bottom: 40px;
    // background: radial-gradient(ellipse farthest-side at top center, rgba(0,0,0,0.08), transparent);

    // &::before {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   top: 0px;
    //   right: 0;
    //   left: 0;
    //   height: 3px;
    //   background: linear-gradient(left, transparent, rgba(0,0,0,0.02), rgba(0,0,0,0.02), transparent);
    // }
  }
`

export const SectionTitle = styled.h2`
  // margin-left: calc(calc(100% / 24) + 50px);
  ${playfairDisplay}
  font-style: italic;
  ${type14}

  color: ${colors.white};
  display: table;
  position: relative;
  padding: 0 10px;
  left: 50px;
  margin-bottom: 0px;

  &:after,
  &:before {
    height: 1px;
    width: 50px;
    position: absolute;
    top: 7px;
    background: ${colors.white};
  }
  &:before {
    left: -50px;
    content: '';
  }
  &:after {
    right: -50px;
    // content: '';
  }
`

export const SectionIntro = styled.div`
  margin: 20px 0 40px;
  // margin-left: ${(props) => props.noPadding ? 'calc(100% / 48 * 1)' : 'calc(100% / 24 * 1)'};
  width: 100%;
  max-width: 800px;

  @media screen and (max-width: ${sizesMax.laptop}) {
    // margin-left: calc(100% / 24 * 1);
  }

  ${workSans}
  &, & p {
    ${type16}
    color: ${colors.white};
  }

  display: block;
  position: relative;
`

export const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  z-index: 1;

  .grid-line {
    ${(props) => {
      const { grid } = props
      return Array(parseInt(grid, 10) + 1)
        .fill(0)
        .map(
          (line, index) => `
            &:nth-child(${index + 1}) {
              left: calc(100% / ${grid} * ${index});
            }
          `
        )
        .join(' ')
    }}
  }
`

export const GridContent = styled.div`
  ${maxWidth}
  height: 100%;
`

export const Line = styled.div`
  position: absolute;
  height: 100%;
  width: 1px;
  background: rgba(${hexToRgb(colors.cream)}, 0.05);
  z-index: 1;
`
