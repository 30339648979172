// Dependencies
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { throttle } from 'lodash'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

// Styles
import { NavWrapper } from './Navigation.styled.js'

// Components
import { Link } from 'gatsby'
import Logo from './Logo'
import Social from './Social'
import { openNavSVG, closeNavSVG } from '../../assets/icons'
import { useEffect } from 'react'

const OFFSET_SCROLL_TO_SHOW_SKINNY_MENU = 10
const PAGE_SCROLL_POSITION_TO_ALLOW_MENU_VISIBILITY_TO_CHANGE = 200
const OFFSET_SCROLL_TO_CHANGE_VISIBILITY = 20

const Navigation = (props) => {
  const [navOpen, setNavOpen] = useState(false)
  const [showSkinny, setShowSkinny] = useState(false)
  const [navVisible, setNavVisible] = useState(true)
  const [direction, setDirection] = useState(-1)
  const [
    positionAtLastChangeOfDirection,
    setPositionAtLastChangeOfDirection,
  ] = useState(0)
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0)
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0)

  const scrollHandler = () => {
    const scrollTop =
      document.documentElement && document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop

    // Cache current scroll position for next iteration
    setCurrentScrollPosition(scrollTop)
  }

  const resizeHandler = () => {
    setNavOpen(false)
    scrollHandler()
  }

  useEffect(() => {
    const doScroll = throttle(scrollHandler, 200)
    const doResize = throttle(resizeHandler, 200)
    window.addEventListener('scroll', doScroll)
    window.addEventListener('resize', doResize)
    scrollHandler()

    return () => {
      window.removeEventListener('scroll', doScroll)
      window.removeEventListener('resize', doResize)
    }
  }, [])

  useEffect(() => {
    let stateChanged = false
    let currDirection = direction

    const newState = {}

    // If the current scroll position is higher than the previous,
    // it means the direction is downwards
    // If the current set direction is not downwards, set it to be downwards,
    // also setting the position at changing of direction

    if (currentScrollPosition > previousScrollPosition && direction === 1) {
      setDirection(-1)
      setPositionAtLastChangeOfDirection(currentScrollPosition)

      // If the current scroll position is lower than the previous,
      // it means the direction is upwards
      // If the current set direction is not upwards, set it to be upwards,
      // also setting the position at changing of direction
    } else if (
      currentScrollPosition < previousScrollPosition &&
      direction === -1
    ) {
      setDirection(1)
      setPositionAtLastChangeOfDirection(currentScrollPosition)
    }

    // If the offset between position at last change of direction and
    // current scroll position is higher than OFFSET_SCROLL_TO_CHANGE_VISIBILITY
    if (
      Math.abs(currentScrollPosition - positionAtLastChangeOfDirection) >
      OFFSET_SCROLL_TO_CHANGE_VISIBILITY
    ) {
      newState.navVisible = !(currDirection < 0)
      stateChanged = true
    }

    // Always show nav if scrolltop position is less than offset
    if (
      currentScrollPosition <
        PAGE_SCROLL_POSITION_TO_ALLOW_MENU_VISIBILITY_TO_CHANGE &&
      (!navVisible || !newState.navVisible)
    ) {
      newState.navVisible = true
    }

    // Always set nav to be skinny if scrolltop position is more than offset
    if (currentScrollPosition > OFFSET_SCROLL_TO_SHOW_SKINNY_MENU) {
      newState.showSkinny = true
      // Otherwise only set it false if not already set
    } else if (showSkinny) {
      newState.showSkinny = false
    }

    // If Nav is Open, make it always visible if set to not visible
    if (navOpen && (!navVisible || !newState.navVisible)) {
      newState.navVisible = true
    }

    // Close menu on scroll
    // if (stateChanged) {
    //   newState.navOpen = false
    // }

    // Set state if any property has been added to newState Object
    if (Object.keys(newState).length) {
      if (Object.keys(newState).includes('navOpen')) {
        setNavOpen(newState.navOpen)
      }
      if (Object.keys(newState).includes('navVisible')) {
        setNavVisible(newState.navVisible)
      }
      if (Object.keys(newState).includes('showSkinny')) {
        setShowSkinny(newState.showSkinny)
      }
    }

    setPreviousScrollPosition(currentScrollPosition)
  }, [currentScrollPosition])

  let headerClass = []
  if (!navVisible && !navOpen) headerClass.push('hide-nav')
  if (showSkinny || navOpen) headerClass.push('skinny')

  return (
    <NavWrapper className={headerClass.join(' ')}>
      <div className={`nav${navOpen ? ' open' : ''}`}>
        <div className="nav__logo">
          <AniLink paintDrip hex="#ffe5cb" duration={1} to={'/'}>
            <Logo {...props} />
          </AniLink>
          {!props.minimalHeader && (
            <button
              className="nav__toggle"
              onClick={() => setNavOpen(!navOpen)}
              dangerouslySetInnerHTML={{
                __html: navOpen ? closeNavSVG : openNavSVG,
              }}
            />
          )}
        </div>
        <div className="nav__content">
          {!props.minimalHeader && (
            <ul>
              {[
                {
                  label: 'Home',
                  link: '/',
                },
                {
                  label: 'Bio',
                  link: '/bio',
                },
                {
                  label: 'Agenda',
                  link: '/agenda',
                },
                {
                  label: 'Musicas',
                  link: '/musicas',
                },
                {
                  label: 'Fotos',
                  link: '/fotos',
                },
                {
                  label: 'Vídeos',
                  link: '/videos',
                },
                {
                  label: 'Contato',
                  link: '/contato',
                },
              ].map((item, id) => (
                <li key={`${item.link}_${id}`}>
                  <AniLink
                    paintDrip
                    hex="#ffe5cb"
                    duration={1}
                    to={item.link}
                    activeClassName="active"
                  >
                    {item.label}
                  </AniLink>
                </li>
              ))}
            </ul>
          )}
          <div className="nav__social">
            <Social {...props} />
          </div>
        </div>
      </div>
    </NavWrapper>
  )
}

// Components PropTypes
Navigation.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }).isRequired,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  spotify: PropTypes.string,
  soundcloud: PropTypes.string,
  youtube: PropTypes.string,
  email: PropTypes.string,
  telephone: PropTypes.string,
}
Navigation.defaultProps = {
  facebook: '',
  instagram: '',
  spotify: '',
  soundcloud: '',
  youtube: '',
  email: '',
  telephone: '',
}

export default Navigation
