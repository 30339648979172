import { useState, useEffect, useRef } from 'react'
import get from 'lodash/get'

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null
}

export const validateRequired = (value) => {
  return typeof value === 'string' || value instanceof String
    ? value.trim().length > 0
    : false
}

export const validateEmail = (value) => {
  return typeof value === 'string' || value instanceof String
    ? value.trim().length > 0 &&
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
    : false
}

export const validatePhone = (value) => {
  return typeof value === 'string' || value instanceof String
    ? value.trim().length > 0 && /^[\+\(\) \-0-9]{5,}$/.test(value)
    : false
}

export const formatTime = (duration) => {
  if (isNaN(duration) || typeof duration !== 'number') {
    return '0:00'
  }

  let time
  let min
  let sec
  let h

  // Calculates seconds
  sec = Math.floor(duration / 1000)
  if (sec >= 60) {
    // if seconds are more than one minute calculate minutes
    min = Math.floor(sec / 60)
    sec -= 60 * min
    if (sec < 10) {
      sec = `0${String(sec)}`
    }
    time = `${String(min)}:${String(sec)}`
  } else {
    if (sec < 10) {
      sec = `0${String(sec)}`
    }
    time = `0:${String(sec)}`
  }
  if (min >= 60) {
    h = Math.floor(min / 60)
    min -= 60 * h
    if (min < 10) {
      min = `0${String(min)}`
    }
    time = `${String(h)}:${String(min)}:${String(sec)}`
  }
  return time
}

export const getYoutubeId = (input) => {
  let url = input
  let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
  let match = url.match(regExp)
  if (match && match[7].length === 11) {
    return match[7]
  } else {
    return input
  }
}

export const getMeta = ({
  title,
  description,
  keywords,
  twitterHandler,
  shareImage,
  siteUrl,
}) => {
  if (
    !title ||
    !description ||
    !keywords ||
    !twitterHandler ||
    !shareImage ||
    !siteUrl
  ) {
    return []
  }
  return [
    { content: title, name: 'author' },
    { content: description, name: 'description' },
    { content: shareImage.file.url, name: 'image' },
    {
      name: 'keywords',
      content: Array.isArray(keywords) ? keywords.join(',') : keywords,
    },

    { content: 'summary_large_image', name: 'twitter:card' },
    { content: twitterHandler, name: 'twitter:site' },
    { content: title, name: 'twitter:title' },
    { content: description, name: 'twitter:description' },
    { content: twitterHandler, name: 'twitter:creator' },
    { content: shareImage.file.url, name: 'twitter:image:src' },
    { content: siteUrl, name: 'twitter:domain' },

    { content: siteUrl, property: 'og:url' },
    { content: 'site', property: 'og:type' },
    { content: title, property: 'og:title' },
    { content: shareImage.file.url, property: 'og:image' },
    { content: description, property: 'og:description' },
    { content: title, property: 'og:site_name' },
    { content: 'en_GB', property: 'og:locale' },

    { content: title, itemprop: 'name' },
    { content: description, itemprop: 'description' },
    { content: shareImage.file.url, itemprop: 'image' },
  ]
}

export const createMetaObject = (general, metaTags = {}, pageTitle = '') => {
  const {
    siteTitle,
    description,
    keywords,
    twitterHandler,
    shareImage,
    siteUrl,
    favicon16x16,
    favicon32x32,
  } = general
  const {
    metaTags: pageMetaTags,
    keywords: pagekeywords,
    shareImage: pageShareImage,
  } = metaTags

  const pageDescription = pageMetaTags.filter(
    (meta) => meta.key === 'description'
  )

  return {
    siteTitle: pageTitle ? `${siteTitle} - ${pageTitle}` : siteTitle,
    description:
      Array.isArray(pageDescription) && pageDescription[0]
        ? pageDescription[0]?.value
        : description,
    keywords: pagekeywords || keywords,
    twitterHandler,
    shareImage: pageShareImage || shareImage,
    siteUrl,
    favicon16x16,
    favicon32x32,
  }
}

export const getLink = ({ siteUrl, favicon16x16, favicon32x32 }) => [
  { rel: 'canonical', href: siteUrl },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: `${favicon16x16.file.url}`,
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: `${favicon32x32.file.url}`,
  },
  { rel: 'shortcut icon', type: 'image/png', href: `${favicon32x32.file.url}` },
]

export const getRandom = (items) =>
  items[Math.floor(Math.random() * items.length)]

const getUniqueIndex = (items, listToExclude) => {
  const randomIndex = Math.floor(Math.random() * items.length)

  return listToExclude.includes(randomIndex)
    ? getUniqueIndex(items, listToExclude)
    : randomIndex
}

export const getRandoms = (items, size, unique = false) => {
  const indexes = []
  for (let index = 0; index < Math.min(size, items.length); index++) {
    const randomIndex = unique
      ? getUniqueIndex(items, indexes)
      : Math.floor(Math.random() * items.length)
    indexes.push(randomIndex)
  }
  return indexes.map((i) => items[i])
}

export const getPageData = (props) => {
  const microcopy = {}
  get(props, 'data.allContentfulMicrocopySet.edges')?.forEach((node) => {
    node?.node?.values?.forEach((value) => {
      microcopy[`${node?.node?.key}.${value.key}`] = { value: value.value }
    })
  })
  const general =
    get(props, 'data.general') ||
    get(props, 'data.allContentfulGeneralContent.edges[0].node')
  const album =
    get(props, 'data.album') || get(props, 'data.contentfulAlbumContent')
  const home =
    get(props, 'data.home') ||
    get(props, 'data.allContentfulHomeContent.edges[0].node')
  const about =
    get(props, 'data.about') ||
    get(props, 'data.allContentfulAboutContent.edges[0].node')
  const contact =
    get(props, 'data.contact') ||
    get(props, 'data.allContentfulContactContent.edges[0].node')
  const images =
    get(props, 'data.images') ||
    get(props, 'data.allContentfulImageGalleriesContent.edges[0].node')
  const videos =
    get(props, 'data.videos') ||
    get(props, 'data.allContentfulVideoGalleriesContent.edges[0].node')
  const events =
    get(props, 'data.events') ||
    get(props, 'data.allContentfulEventsContent.edges[0].node')
  const dates = get(props, 'data.allContentfulEventContent.edges')
  const music =
    get(props, 'data.music') ||
    get(props, 'data.allContentfulMusicContent.edges[0].node')
  const social =
    get(props, 'data.social') ||
    get(props, 'data.allContentfulSocialContent.edges[0].node')

  return {
    microcopy,
    general,
    album,
    home,
    about,
    contact,
    images,
    videos,
    events,
    dates,
    music,
    social,
  }
}

export const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
