// Dependencies
import styled from 'styled-components'

// Constants
import { colors, creamGradient } from '../../constants/colors'
import { transition } from '../../constants/general'
import { playfairDisplay, type14 } from '../../constants/fonts'
import { sizesMin, sizesMax } from '../../constants/breakpoints'

export const SocialWrapper = styled.div`
  position: relative;
  display: inline-block;

  .social-content {
    display: table;
    padding: 0 25px;
  }

  .social-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    padding: 5px;
    height: 70px;
    align-items: center;

    .fb-like, .g-ytsubscribe, iframe {
      margin: 0 20px;
    }

    .fb-like {
      width: 113px;
    }

    @media screen and (max-width: ${sizesMax.laptop}) {
      margin-left: -50px;
      margin-top: -10px
    }
    @media screen and (max-width: ${sizesMax.mobileM}) {
      flex-direction: column;
      height: auto;
      margin-left: 0px;

      .fb-like, .g-ytsubscribe, iframe {
        margin: 5px 0;
        display: block;
      }

      .fb-like {
        margin-left: 5px;
      }
    }
  }

  a {
    padding: 5px;
    height: 70px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    ${transition}
  }

  svg {
    width: 30px;
    height: 30px;

    * {
      fill: ${colors.black};
      ${transition}
    }
  }

  a:hover {
    background: ${colors.white};
    svg * {
      fill: ${colors.black};
    }
  }
`
