// Dependencies
import styled from 'styled-components'

// Helpers
import { hexToRgb } from '../../helpers/utils'

// Constants
import { colors, creamGradient } from '../../constants/colors'
import {
  type30,
  type20,
  type14,
  type25,
  playfairDisplay,
  type50,
  type10,
  type16,
  type18,
} from '../../constants/fonts'
import { sizesMax } from '../../constants/breakpoints'
import { maxWidth, transition } from '../../constants/general'

export const PlayerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 65px;
  background: ${colors.cream};
  z-index: 100;
  ${transition}

  .album__player__playlist {
    .album {
      display: flex;
      color: ${colors.white};
      background: ${colors.cream} !important;
      ${transition}
      ${type20}
            width: 100%;
      font-weight: bold;
      padding: 15px 20px 15px 0;

      &__title {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 0px;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: ${sizesMax.laptop}) {
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;

          h4 {
            margin-bottom: -10px;
          }
        }

        h4 {
          ${type30}
          color: ${colors.dark};
          font-weight: bold;
        }

        div {
          margin-top: -5px;
        }

        small {
          ${type10}
          color: ${colors.dark};
          background: ${colors.white};
          padding: 2px 5px;
          display: inline;
          font-style: italic;
          margin-right: 5px;
          font-weight: normal;
          border-radius: 3px;
          margin-bottom: 5px;
        }
      }

      &__image {
        width: 50px;
        height: 50px;
        position: relative;
        margin: 5px 20px;
      }
    }

    &::before {
      content: '';
      position: fixed;
      top: 0;
      width: 100%;
      height: calc(100vh - 65px);
      background: rgba(${hexToRgb(colors.dark)}, 0.9);
    }

    position: fixed;
    bottom: 65px;
    width: 100%;
    max-height: calc(100vh - 65px);
    overflow: scroll;

    & > div {
      position: relative;
      ${maxWidth}
      display: flex;
      flex-direction: column;
      ${transition}
    }

    .track,
    .album {
      color: ${colors.white};
      background: ${colors.black};
      ${transition}

      border-top: solid 1px rgba(${hexToRgb(colors.dark)}, 1);
      &:first-child {
        margin-top: 0;
      }

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        border: 0;
        cursor: pointer;
        background: transparent;
        text-align: left;

        span {
          margin: 0 20px 0 0px;
          ${transition}
          border-radius: 50%;
          width: 44px;
          height: 44px;
          border: solid 2px ${colors.cream};
          align-self: center;
        }

        svg {
          width: 40px;
          height: 40px;
          padding: 0px;
          ${transition}
          circle {
            fill: rgba(${hexToRgb(colors.cream)}, 0);
            ${transition}
          }
          path,
          polygon {
            fill: rgba(${hexToRgb(colors.cream)}, 1);
            ${transition}
          }
        }
      }
    }
    .track {
      cursor: pointer;
      .album__image {
        width: 40px;
        height: 40px;
        position: relative;
        margin: 5px 20px 0 0;
      }

      &__title,
      &__duration {
        color: ${colors.white};
        ${type16}

        small {
          ${type10}
          color: ${colors.white};
          display: block;
          font-style: italic;
          opacity: 0.5;
          margin-top: 2px;
          font-weight: normal;
        }
      }
      &__title {
        ${type20}
        width: 100%;
        font-weight: bold;
      }

      &.active,
      &.active:hover {
        background: ${colors.white};

        .track__title,
        .track__duration {
          color: ${colors.black};

          small {
            color: ${colors.dark};
          }
        }

        button {
          span {
            border-color: ${colors.black};
          }
          svg {
            circle {
              fill: rgba(${hexToRgb(colors.cream)}, 0);
            }
            path,
            polygon {
              fill: rgba(${hexToRgb(colors.black)}, 1);
            }
          }
        }
      }
      &:hover {
        background: ${colors.white};

        .track__title,
        .track__duration {
          color: ${colors.black};

          small {
            color: ${colors.dark};
          }
        }

        button {
          span {
            border-color: ${colors.black};
          }
          svg {
            circle {
              fill: rgba(${hexToRgb(colors.cream)}, 0);
            }
            path,
            polygon {
              fill: rgba(${hexToRgb(colors.black)}, 1);
            }
          }
        }
      }
    }
  }

  .album__player__interface {
    position: relative;
    ${maxWidth}
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 25px;
    ${transition}

    .controls {
      display: flex;
      flex-direction: row;
      text-align: left;
      height: 60px;
      ${transition}

      button {
        margin: 0 2px;
        padding: 0;
        border: 0;
        cursor: pointer;
        background: rgba(${hexToRgb(colors.white)}, 0);
        ${transition}
        width: 34px;
        height: 34px;
        align-self: center;

        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 3px;

        svg {
          width: 30px;
          height: 30px;
          padding: 0px;
          ${transition}
          circle {
            fill: rgba(${hexToRgb(colors.black)}, 0);
            ${transition}
          }
          rect,
          path,
          polygon {
            fill: rgba(${hexToRgb(colors.black)}, 1);
            ${transition}
          }
        }

        &:hover,
        &.active {
          background: rgba(${hexToRgb(colors.white)}, 1);
          svg {
            rect,
            path,
            polygon {
              fill: rgba(${hexToRgb(colors.black)}, 1);
            }
          }
        }
      }
    }
    .elapsed,
    .track {
      text-align: right;
      strong,
      small {
        color: ${colors.black};
        ${type20}
      }
      strong {
        font-weight: bold;
      }
      small {
        ${type14}
        margin-left: 10px;
        &::before {
          content: '•';
          display: inline-block;
          padding-right: 10px;
          ${type14}
          color: ${colors.black};
        }
      }

      @media screen and (max-width: ${sizesMax.laptop}) {
        text-align: left;
        strong {
          ${type14}
        }
        small {
          margin-top: 5px;
          margin-left: 0px;
          &::before {
            display: none;
          }
          display: block;
          ${type10}
        }
      }
    }
  }
  .bar {
    background: rgba(${hexToRgb(colors.black)}, 1);
    border-top: solid 1px rgba(${hexToRgb(colors.black)}, 1);
    height: 5px;
    width: 100%;
    bottom: 0px;
    position: absolute;
    display: block;
    overflow: hidden;
    cursor: pointer;
    ${transition}

    .fill {
      background: rgba(${hexToRgb(colors.white)}, 1);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: block;
      ${transition}
    }
  }
  &:hover {
    .bar {
      height: 10px;
    }
  }
`

export const MusicasWrapper = styled.div`
    > div {
        width: 100%;
    }

    padding-top: 10px;

    &:not(:last-child){
        padding-bottom: 30px;
    }

    &.compact {
        padding-bottom: 0 !important;
    }

    .album, .album-release {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: calc(100% / 11 * 11);

        margin-bottom: 40px;
        

        @media screen and (max-width: ${sizesMax.laptop}) {
            width: 100%;
        }

        &__image {
            width: 45%;
            position: relative;
            .gatsby-image-wrapper {
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
            }

            @media screen and (max-width: ${sizesMax.laptop}) {
                width: 100%;
            }
        }

        &__player {
            width: 55%;
            position: relative;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

            @media screen and (max-width: ${sizesMax.laptop}) {
                width: 100%;
            }
        }

        &__player__playlist {
            .track {
                cursor: pointer;
                color: ${colors.white};
                background: ${colors.black};
                ${transition}

                margin-top: 5px;
                &:first-child {
                    margin-top: 0;
                }

                button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    padding: 10px 20px;
                    border: 0;
                    cursor: pointer;
                    background: transparent;
                    text-align: left;

                    span {
                        margin: 0 20px 0 0px;
                        ${transition}
                        border-radius: 50%;
                        width: 44px;
                        height: 44px;
                        border: solid 2px ${colors.cream};
                        align-self: center;
                    }

                    svg {
                        width: 40px;
                        height: 40px;
                        padding: 0px;
                        ${transition}
                        circle {
                            fill: rgba(${hexToRgb(colors.cream)}, 0);
                            ${transition}
                        }
                        path, polygon {
                            fill: rgba(${hexToRgb(colors.cream)}, 1);
                            ${transition}
                        }
                    }
                }

                &__title, &__duration {
                    color: ${colors.white};
                    ${type16}
                    small {
                        ${type10}
                        color: ${colors.white};
                        display: block;
                        font-style: italic;
                        opacity: .5;
                        margin-top: 2px;
                        font-weight: normal;
                    }
                }
                &__title {
                    ${type20}
                    width: 100%;
                    font-weight: bold;
                }

                &.active, &.active:hover {
                    background: ${colors.white};

                    .track__title, .track__duration {
                        color: ${colors.black};

                        small {
                            color: ${colors.dark};
                        }
                    }

                    button {
                        span {
                            border-color: ${colors.black};
                        }
                        svg {
                            circle {
                                fill: rgba(${hexToRgb(colors.cream)}, 0);
                            }
                            path, polygon {
                                fill: rgba(${hexToRgb(colors.black)}, 1);
                            }
                        }
                    }
                }
                &:hover {
                    background: ${colors.white};

                    .track__title, .track__duration {
                        color: ${colors.black};

                        small {
                            color: ${colors.dark};
                        }
                    }

                    button {
                        span {
                            border-color: ${colors.black};
                        }
                        svg {
                            circle {
                                fill: rgba(${hexToRgb(colors.cream)}, 0);
                            }
                            path, polygon {
                                fill: rgba(${hexToRgb(colors.black)}, 1);
                            }
                        }
                    }
                }
            }
        }
    }
    .album {
        .album__image {
            padding-left: 60px;
            position: relative;
        }
        .album__social {
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            width: 60px;

            .social-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 10px 0;
                margin: 0;
                background: ${colors.cream};

                a {
                    height: 40px;
                    width: 60px;

                    svg {
                        margin: 0 auto;
                    }
                }

                @media screen and (max-width: ${sizesMax.laptop}) {
                    background: transparent;
                }
            }

            @media screen and (max-width: ${sizesMax.laptop}) {
                background: ${colors.cream};
                height: 100%
            }
        }
    }
    .album-release {
        margin-bottom: 0;

        .album__image, .album__player__interface, .album__player {
            width: 100%;
        }

        .album__image {
            background: ${colors.cream} !important;
        }

        .album__social {
            text-align: center;
        }
    }
    .compact {
        .album__image, .album__player__interface {
            display: none;
        }

        .album__player {
            width: 100%;
        }

        .album__player {
            display: flex;
            flex-direction: row;
        }

        .album__player__interface {
            width: 50%;
        }

        .album__player__playlist {
            width: 100%;
        }
    }
}`

export const CountdownWrapper = styled.div`
  background: ${colors.black};
  color: ${colors.cream};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: -50px auto;
  padding: 10px;
  height: 100px;
  width: 400px;

  @media screen and (max-width: ${sizesMax.tablet}) {
    margin: -25px auto;
    height: 50px;
    width: 200px;
  }
`
export const CountdownBlock = styled.div`
  align-self: center;
  ${type30}
  @media screen and (max-width: ${sizesMax.tablet}) {
    ${type20}
  }

  .loading {
    width: 50px;
    height: 50px;

    svg * {
      fill: ${colors.cream};
    }
  }
`
