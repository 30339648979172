// Dependencies
import React from 'react'
import { previewSVG } from '../../assets/icons'

const Post = ({ post }) => {
  let liClass = 'juicer__post ' + post.source.term + ' external-link '
  let link = ''

  if (post.source.source === 'Instagram') {
    liClass += 'instagram '
    link = 'http://instagram.com/p/' + post.external_id
  }

  if (post.source.source === 'Facebook') {
    liClass += 'facebook '
    link =
      'https://www.facebook.com/' +
      post.source.term +
      '/posts/' +
      post.external_id
  }

  if (post.image) {
    liClass += 'imagePost '
  }

  const text = post.message
  const img = post.image ? <img src={post.image} alt="" /> : null

  return (
    <div className={liClass}>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        onClick={
          (e) => {}
          // trackCustomEvent({
          //   category: 'Feed',
          //   action: 'Click',
          //   label: 'Item - ' + link,
          // })
        }
      >
        <div className="card">
          <div className="card__picture">
            <div>{img}</div>
            <div className="card__icon" dangerouslySetInnerHTML={{ __html: previewSVG }} />
          </div>
          <div
            className="card__info"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </a>
    </div>
  )
}

export default Post
