export const maxWidthValue = '1200px'
export const sizesMax = {
  mobileS: '319px',
  mobileM: '374px',
  mobileL: '424px',
  tablet: '767px',
  laptop: '1023px',
  laptopL: '1439px',
  desktop: '2559px',
}
export const sizesMin = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const breakpoints = {
  mobileS: `(min-width: ${sizesMin.mobileS})`,
  mobileM: `(min-width: ${sizesMin.mobileM})`,
  mobileL: `(min-width: ${sizesMin.mobileL})`,
  tablet: `(min-width: ${sizesMin.tablet})`,
  laptop: `(min-width: ${sizesMin.laptop})`,
  laptopL: `(min-width: ${sizesMin.laptopL})`,
  desktop: `(min-width: ${sizesMin.desktop})`,
  desktopL: `(min-width: ${sizesMin.desktop})`,
}

export default breakpoints
