// Dependencies
import React, { useState } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { Parallax } from 'react-parallax'
import Slider from 'react-slick'

// Styles
import { HeroWrapper } from './Hero.styled'
import '../../assets/slick/slick.css'
import '../../assets/slick/slick-theme.css'

// Components
import NextArrow from '../Modal/NextArrow'
import PrevArrow from '../Modal/PrevArrow'
import { loadingSVG } from '../../assets/icons'

const Hero = ({ images, noParallax = false }) => {
  const [loaded, setLoaded] = useState(false)
  const multi = images.length > 1
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: false,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnFocus: true,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    touchThreshold: 50,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  const onLoad = () => {
    setLoaded(true)
  }
  const onStartLoad = () => {
    setLoaded(false)
  }

  return multi ? (
    <HeroWrapper>
      <>
        {!loaded && (
          <div className="loading">
            <span dangerouslySetInnerHTML={{ __html: loadingSVG }} />
          </div>
        )}
        <div className="hero-content">
          <Slider {...settings}>
            {images.map((image, index) => {
              const key = 'hero_' + index

              return noParallax ? (
                <Img
                  fluid={image.fluid}
                  onLoad={onLoad}
                  onStartLoad={onStartLoad}
                />
              ) : (
                <Parallax
                  key={key}
                  bgImage={image.fluid.src}
                  bgImageSizes={image.fluid.sizes}
                  bgImageSrcSet={image.fluid.srcSet}
                  strength={200}
                >
                  <div className="hero-content" />
                </Parallax>
              )
            })}
          </Slider>
        </div>
      </>
    </HeroWrapper>
  ) : (
    <HeroWrapper>
      <>
        {!loaded && (
          <div className="loading">
            <span dangerouslySetInnerHTML={{ __html: loadingSVG }} />
          </div>
        )}
        {noParallax ? (
          <Img
            fluid={images[0].fluid}
            onLoad={onLoad}
            onStartLoad={onStartLoad}
          />
        ) : (
          <Parallax
            bgImage={images[0].fluid.src}
            bgImageSizes={images[0].fluid.sizes}
            bgImageSrcSet={images[0].fluid.srcSet}
            strength={200}
          >
            <div className="hero-content" />
          </Parallax>
        )}
      </>
    </HeroWrapper>
  )
}

// Components PropTypes
Hero.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
      }),
    })
  ),
}

export default Hero
