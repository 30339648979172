// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Components
import { prevSVG } from '../../assets/icons'

const PrevArrow = ({ onClick }) => (
  <button
    className="arrow prev"
    onClick={onClick}
    dangerouslySetInnerHTML={{ __html: prevSVG }}
  />
)

// Components PropTypes
PrevArrow.propTypes = {
  onClick: PropTypes.func,
}

export default PrevArrow
