// Dependencies
import styled from 'styled-components'

// Helpers
import { hexToRgb } from '../../helpers/utils'

// Constants
import { colors } from '../../constants/colors'
import { sizesMax, sizesMin } from '../../constants/breakpoints'
import { transition } from '../../constants/general'

export const SocialWrapper = styled.div`
  .slick-dots {
    bottom: 10px;
  }
  margin-bottom: 132px;

  @media screen and (min-width: 0px) and (max-width: ${sizesMax.tablet}) {
    margin-bottom: 102px;
  }

  button.arrow {
    background: ${colors.black};
    border: none;
    padding: 5px 6px;
    display: block;
    position: absolute;
    top: 0;
    z-index: 99999;
    cursor: pointer;
    ${transition}
    height: 100%;

    &.prev {
      left: 0px;
    }

    &.next {
      right: 0;
    }

    svg {
      margin: 0 auto;
      display: block;
      position: relative;
      overflow: hidden;
      width: 30px;
      height: 30px;
      circle {
        fill: rgba(${hexToRgb(colors.cream)}, 0);
        ${transition}
      }
      polygon {
        fill: rgba(${hexToRgb(colors.cream)}, 1);
        ${transition}
      }
    }
    &:hover {
      background: ${colors.white};
      svg {
        polygon {
          fill: rgba(${hexToRgb(colors.black)}, 1);
        }
      }
      opacity: 1;
    }
  }

  .juicer,
  .juicer-slider {
    position: relative;
    display: block;
    padding: 0 42px;
    background: ${colors.black};

    &__list {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
    }

    .swiper-container:hover .card__picture > div {
      &::after {
        background: rgba(${hexToRgb(colors.black)}, 0.5);
      }
    }

    &__post {
      img {
        width: 100%;
      }

      margin: 0 0 30px 0;

      > a {
        color: ${colors.cream};

        p {
          margin: 0;
          font-size: 0.9rem;
          & {
            display: inline-block;
          }
        }
      }

      .card {
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.2);
        transition: ${transition};

        overflow: hidden;
        display: flex;
        position: relative;
        flex-direction: column;
        cursor: pointer;

        &__icon {
          position: absolute !important;
          top:0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;

          svg {
            background: ${colors.white};
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -20px 0 0 -20px;
            width: 40px;
            height: 40px;
            opacity: 0;
            padding: 0px;
            z-index: 20;
            ${transition}
      
            circle {
              fill: rgba(${hexToRgb(colors.black)}, 0);
              ${transition}
            }
            path {
              fill: rgba(${hexToRgb(colors.black)}, 1);
              ${transition}
            }
          }
        }

        &__picture {
          width: 100%;
          height: 100%;
          // overflow: hidden;
          background: ${colors.black};

          > div {
            position: relative;
            display: block;
            overflow: hidden;

            &::after {
              content: '';
              position: absolute;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              left: 0;
              top: 0;
              background: rgba(${hexToRgb(colors.black)}, 0);
              ${transition}
              border: solid 5px rgba(${hexToRgb(colors.white)}, 0);
            }
          }
        }

        &:hover .card__picture > div {
          &::after {
            background: rgba(${hexToRgb(colors.black)}, 0) !important;
            border: solid 5px rgba(${hexToRgb(colors.white)}, 1);
          }

          svg {
            background: ${colors.white};
            circle {
              fill: rgba(${hexToRgb(colors.cream)}, 0);
            }
            path {
              fill: rgba(${hexToRgb(colors.black)}, 1);
            }
            opacity: 1;
          }
        }

        &__info {
          display: none;
          padding: 20px;
        //   position: absolute;
        //   opacity: 0;
        //   transition: ${transition};
        //   z-index: 2;
        //   bottom: 10px;
        //   max-height: calc(100% - 15px);
        //   overflow: hidden;
        }
      }
    }
  }
  .juicer {
    .juicer__post {
      // @media screen and (min-width: 0px) and (max-width: ${sizesMax.tablet}) {
      //   width: calc(100% / 11 * 11);
      //   margin-right: 0;
      // }
      @media screen and (min-width: 0) and (max-width: ${sizesMax.laptop}) {
        width: calc(100% / 11 * 5);
        margin-right: calc(
          calc(50% - calc(100% / 11 * 5)) + calc(50% - calc(100% / 11 * 5))
        );
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      @media screen and (min-width: ${sizesMin.laptop}) {
        width: calc(100% / 11 * 3);
        margin-right: calc(
          calc(calc(100% / 3) - calc(100% / 11 * 3)) +
            calc(calc(calc(100% / 3) - calc(100% / 11 * 3)) / 2)
        );
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    &-slider {
      margin-top: 0px;
      .juicer__post {
        width: 100% !important;
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
      }
      .slick-dots {
        display: none !important;
      }
    }
  }
`
