import { sizesMax } from './breakpoints'

export const workSans = `
  font-family: 'Work Sans', sans-serif;
  font-smooth: always;
  font-weight: 400;
`

export const playfairDisplay = `
  font-family: "Playfair Display", serif;
  font-smooth: always;
  font-weight: 400;
`

export const type40 = `
  font-size: 40px;
  @media screen and (max-width: ${sizesMax.tablet}) {
    font-size: 35px;
  }
  @media screen and (max-width: ${sizesMax.mobileL}) {
    font-size: 30px;
  }
  @media screen and (max-width: ${sizesMax.mobileS}) {
    font-size: 28px;
  }
`

export const type50 = `
  font-size: 50px;
  @media screen and (max-width: ${sizesMax.tablet}) {
    font-size: 45px;
  }
  @media screen and (max-width: ${sizesMax.mobileL}) {
    font-size: 35px;
  }
  @media screen and (max-width: ${sizesMax.mobileS}) {
    font-size: 30px;
  }
`

export const type30 = `
  font-size: 30px;
  @media screen and (max-width: ${sizesMax.tablet}) {
    font-size: 25px;
  }
  @media screen and (max-width: ${sizesMax.mobileL}) {
    font-size: 22px;
  }
  @media screen and (max-width: ${sizesMax.mobileS}) {
    font-size: 20px;
  }
`

export const type25 = `
  font-size: 25px;
  @media screen and (max-width: ${sizesMax.tablet}) {
    font-size: 20px;
  }
  @media screen and (max-width: ${sizesMax.mobileL}) {
    font-size: 16px;
  }
  @media screen and (max-width: ${sizesMax.mobileS}) {
    font-size: 14px;
  }
`

export const type20 = `
  font-size: 20px;
  @media screen and (max-width: ${sizesMax.tablet}) {
    font-size: 18px;
  }
  @media screen and (max-width: ${sizesMax.mobileS}) {
    font-size: 16px;
  }
`

export const type18 = `
  font-size: 18px;
  @media screen and (max-width: ${sizesMax.tablet}) {
    font-size: 16px;
  }
  @media screen and (max-width: ${sizesMax.mobileS}) {
    font-size: 14px;
  }
`

export const type16 = `
  font-size: 16px;
  @media screen and (max-width: ${sizesMax.tablet}) {
    font-size: 14px;
  }
`

export const type14 = `
  font-size: 14px;
`

export const type10 = `
  font-size: 10px;
`
