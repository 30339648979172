import React from 'react'
//
import { Context as MicroCopyContext } from '../context/microcopyContext'

export const getMicroCopy = ({
  key,
  tokens = {},
  fallback = null,
  data = {},
}) => {
  if (!data[key]) {
    return key
  }

  const { value } = data[key]

  const replacements = {}
  Object.keys(tokens).forEach((key) => {
    replacements[`{${key}}`] = tokens[key]
  })

  if (value && Object.keys(replacements).length) {
    var re = new RegExp(Object.keys(replacements).join('|'), 'gi')
    const result = value.replace(re, (matched) => {
      return replacements[matched]
    })

    return result
  }

  if (value) {
    return value
  }

  return fallback
}

export const MicroCopy = ({ name, tokens, fallback }) => {
  const data = React.useContext(MicroCopyContext) || {}
  const value = getMicroCopy({ name, tokens, fallback, data })

  return <React.Fragment>{value}</React.Fragment>
}
