// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Styles
import { LogoWrapper } from './Logo.styled.js'

const Logo = ({ siteTitle, logo }) => {
  return (
    <LogoWrapper>
      <div>
        <div className="content">
          <h1
            dangerouslySetInnerHTML={{
              __html:
                (siteTitle ? siteTitle : '') +
                (logo &&
                logo.childMarkdownRemark &&
                logo.childMarkdownRemark.html
                  ? +'\n' + logo.childMarkdownRemark.html
                  : ''),
            }}
          />
        </div>
      </div>
    </LogoWrapper>
  )
}

// Components PropTypes
Logo.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }).isRequired,
}
Logo.defaultProps = {}

export default Logo
