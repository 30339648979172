// Dependencies
import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

// Components
import Post from './Post'
import NextArrow from '../Modal/NextArrow'
import PrevArrow from '../Modal/PrevArrow'

// Styles
import 'swiper/swiper-bundle.css'
import breakpoints, { sizesMax } from '../../constants/breakpoints'

// Helpers
import { useWindowDimensions } from '../../helpers/utils'

const Feed = ({ posts, slider: sliderEnabled = false }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const { width } = useWindowDimensions()
  const ref = useRef(null)

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      if (width >= parseInt(sizesMax.laptopL.replace('px', ''), 10)) {
        ref.current.swiper.slideTo(activeSlide + 3)
      } else if (width >= parseInt(sizesMax.laptop.replace('px', ''), 10)) {
        ref.current.swiper.slideTo(activeSlide + 2)
      } else {
        ref.current.swiper.slideTo(activeSlide + 1)
      }
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      if (width >= parseInt(sizesMax.laptopL.replace('px', ''), 10)) {
        ref.current.swiper.slideTo(activeSlide - 3)
      } else if (width >= parseInt(sizesMax.laptop.replace('px', ''), 10)) {
        ref.current.swiper.slideTo(activeSlide - 2)
      } else {
        ref.current.swiper.slideTo(activeSlide - 1)
      }
    }
  }

  const params = {
    slidesPerView: 1,
    activeSlideKey: 0,
    spaceBetween: 0,
    onSlideChange: (swiper) => {
      setActiveSlide(swiper?.activeIndex)
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      [parseInt(sizesMax.laptopL.replace('px', ''), 10)]: {
        slidesPerView: 3,
      },
      [parseInt(sizesMax.laptop.replace('px', ''), 10)]: {
        slidesPerView: 2,
      },
      [parseInt(sizesMax.tablet.replace('px', ''), 10) - 100]: {
        slidesPerView: 1,
      },
    },
  }

  return sliderEnabled ? (
    <>
      <Swiper {...params} ref={ref}>
        {posts.map((post, i) => {
          const key = 'juicer_post_' + i
          return (
            <SwiperSlide key={key}>
              <Post post={post} source={post.source.source} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <PrevArrow onClick={goPrev} />
      <NextArrow onClick={goNext} />
    </>
  ) : (
    <div className="juicer__list">
      {posts.map((post, i) => {
        const key = 'juicer_post_' + i
        return <Post key={key} post={post} source={post.source.source} />
      })}
    </div>
  )
}

export default Feed
