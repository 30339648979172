export const colors = {
  dark: '#231f20',
  light: '#f5f5f5',
  white: '#ffffff',
  black: '#000000',
  grey: '#222222',
  cream: '#ffe5cb',
  creamVariation: '#fdcc9a',
  danger: '#ffc551',
}

export const creamGradient = `
    background: rgb(253,204,154);
    background: -moz-linear-gradient(135deg, rgba(255,229,203,1) 0%, rgba(253,204,154,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(255,229,203,1) 0%, rgba(253,204,154,1) 100%);
    background: linear-gradient(135deg, rgba(255,229,203,1) 0%, rgba(253,204,154,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdcc9a",endColorstr="#fdcc9a",GradientType=1);
`

export const creamGradientReverse = `
    background: rgb(255,229,203,1);
    background: -moz-linear-gradient(135deg, rgba(253,204,154,1) 0%, rgba(253,204,154,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(253,204,154,1) 0%, rgba(253,204,154,1) 100%);
    background: linear-gradient(135deg, rgba(253,204,154,1) 0%, rgba(253,204,154,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdcc9a",endColorstr="#fdcc9a",GradientType=1);
`
