// Dependencies
import React, { useEffect, useState, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

// Components
import Feed from './Feed'
import { loadingSVG } from '../../assets/svgs'

// Styles
import { SocialWrapper } from './Social.styled'

// Helpers, Context
import { Context as MicroCopyContext } from '../../context/microcopyContext'
import { getMicroCopy } from '../../helpers/microcopy'

const Social = ({ juicerFeedId = '', compact = false }) => {
  const { state } = useContext(MicroCopyContext)
  const [isLoadingFeed, setIsLoadingFeed] = useState(false)
  const [removeLoadMoreButton, setRemoveLoadMoreButton] = useState(false)
  const [page, setPage] = useState(1)
  const [posts, setPosts] = useState([])
  const limit = 9

  const getSocialFeed = async () => {
    // Request
    if (!isLoadingFeed) {
      setIsLoadingFeed(true)
      var url = `//www.juicer.io/api/feeds/${juicerFeedId}?per=${limit}&page=${page}`

      const response = await axios(url)
      const data = response.data

      if (
        data.posts !== undefined &&
        data.posts.items !== undefined &&
        !!data.posts.items.length
      ) {
        const newPosts = [...posts, ...data.posts.items]
        setPosts(newPosts)
        if (newPosts.length % limit !== 0 || data.posts.items.length < limit) {
          setRemoveLoadMoreButton(true)
        }
      }

      setPage(page + 1)
      setIsLoadingFeed(false)
    }
  }

  const initFetch = useCallback(getSocialFeed, [])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  return (
    <SocialWrapper>
      <div className={`content ${compact ? 'juicer-slider' : 'juicer'}`}>
        <Feed posts={posts} slider={compact} />
        {!compact && !removeLoadMoreButton ? (
          <button
            className="button"
            onClick={(e) => {
              getSocialFeed()
            }}
          >
            <span>
              {getMicroCopy({ key: `ctas.seeMorePosts`, data: state?.value })}
              {isLoadingFeed && (
                <div dangerouslySetInnerHTML={{ __html: loadingSVG }} />
              )}
            </span>
          </button>
        ) : null}
      </div>
    </SocialWrapper>
  )
}

// Components PropTypes
Social.propTypes = {
  juicerFeedId: PropTypes.string.isRequired,
  compact: PropTypes.bool.isRequired,
}

export default Social
