// Dependencies
import styled from 'styled-components'

// Constants
import { sizesMax, sizesMin } from '../../constants/breakpoints'
import { transition } from '../../constants/general'
import { colors } from '../../constants/colors'

// Helpers
import { hexToRgb } from '../../helpers/utils'

export const HeroWrapper = styled.div`
  width: 100%;
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 70px;
    width: 100%;
    position: relative;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.black};
    background: linear-gradient(180deg, #000000, #111111);
    background-size: 400% 400%;
    // background: linear-gradient(180deg, #ffe5cb, #fdcc9a);
    // background-size: 400% 400%;

    -webkit-animation: LoadingBG 10s ease infinite;
    -moz-animation: LoadingBG 10s ease infinite;
    animation: LoadingBG 10s ease infinite;

    @-webkit-keyframes LoadingBG {
      0% {
        background-position: 50% 0%;
      }
      50% {
        background-position: 51% 100%;
      }
      100% {
        background-position: 50% 0%;
      }
    }
    @-moz-keyframes LoadingBG {
      0% {
        background-position: 50% 0%;
      }
      50% {
        background-position: 51% 100%;
      }
      100% {
        background-position: 50% 0%;
      }
    }
    @keyframes LoadingBG {
      0% {
        background-position: 50% 0%;
      }
      50% {
        background-position: 51% 100%;
      }
      100% {
        background-position: 50% 0%;
      }
    }

    svg {
      width: 70px;
      height: 70px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -35px 0 0 -35px;

      * {
        fill: ${colors.creamVariation};
      }
    }
  }

  .hero-content {
    height: 70vh;
    max-height: 70vh;
    min-height: 30vh;
  }

  @media screen and (max-width: ${sizesMax.laptop}) {
    width: 100%;

    .react-parallax-bgimage {
      margin-top: -100px;
    }

    .hero-content {
      height: 50vh;
      min-height: 50vh;
    }
  }

  .slick-dots {
    bottom: 10px;
  }

  button.arrow {
    background: ${colors.black};
    border: none;
    padding: 5px 6px;
    display: block;
    position: absolute;
    bottom: 3px;
    z-index: 99999;
    cursor: pointer;
    ${transition}

    &.prev {
      left: 0;

      @media screen and (max-width: ${sizesMax.laptop}) {
        left: auto;
        right: 42px;
      }
    }

    &.next {
      left: 42px;

      @media screen and (max-width: ${sizesMax.laptop}) {
        left: auto;
        right: 0;
      }
    }

    svg {
      margin: 0 auto;
      display: block;
      position: relative;
      overflow: hidden;
      width: 30px;
      height: 30px;
      circle {
        fill: rgba(${hexToRgb(colors.cream)}, 0);
        ${transition}
      }
      polygon {
        fill: rgba(${hexToRgb(colors.cream)}, 1);
        ${transition}
      }
    }
    &:hover {
      background: ${colors.white};
      svg {
        polygon {
          fill: rgba(${hexToRgb(colors.black)}, 1);
        }
      }
      opacity: 1;
    }
  }
`
