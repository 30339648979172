// Dependencies
import styled from 'styled-components'

// Constants
import {
  colors,
  creamGradient,
  creamGradientReverse,
} from '../../constants/colors'
import { transition } from '../../constants/general'
import { sizesMin, sizesMax, maxWidthValue } from '../../constants/breakpoints'
import {
  playfairDisplay,
  type30,
  type20,
  type18,
  type16,
  type14,
  type25,
} from '../../constants/fonts'
import { hexToRgb } from '../../helpers/utils'

export const NavWrapper = styled.nav`
  background: ${colors.cream};
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  z-index: 10;

  ${transition}
  will-change: transform, background;

  transform: translate3d(0px, 0px, 0);
  &.hide-nav {
    transform: translate3d(0px, -120px, 0);
  }
  &.skinny {
  }

  .nav {
    max-width: ${maxWidthValue};
    display: flex;
    margin: 0 auto;
    width: 100%;

    &__toggle {
      display: none;
      border: 0;
      background: transparent;
      margin: 0;
    }
  }

  @media screen and (max-width: ${sizesMax.laptopL}) {
    .nav {
      background: rgba(${hexToRgb(colors.cream)}, 0.95);
      height: 70px;
      display: flex;
      flex-direction: column;

      &__logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__toggle {
        display: inline-flex;
        padding: 10px 30px;

        svg {
          width: 40px;
          height: 40px;
          * {
            fill: ${colors.black};
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        display: none;
        justify-content: space-evenly;
      }

      &__social {
        display: block;
        margin: 0 auto;
        padding: 0;
      }

      &.open {
        height: 100vh;

        .nav__content {
          display: flex;
          height: calc(100vh - 70px);
        }
      }
    }

    ul {
      padding: 20px;
      width: 100%;

      li {
        margin: 35px auto;
        width: 100%;
        text-align: center;

        & a {
          color: ${colors.black};
          ${type25}
          ${playfairDisplay}
          line-height: 1.3;
          font-weight: bold;
          text-decoration: none;
          padding: 10px 10px;
          ${transition}
          position: relative;

          &:hover,
          &.active {
            color: ${colors.black};
            background: ${colors.white};
            font-weight: bold;
          }
          &.active::after {
            content: '';
            height: 1px;
            background: ${colors.black};
            width: calc(100% - 20px);
            position: absolute;
            left: 0;
            top: calc(50% + 13px);
            margin: 0 10px;
          }
        }
        ${transition}
      }
    }
  }

  @media screen and (min-width: ${sizesMin.laptopL}) {
    display: inline-block;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav__content {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
    }

    ul {
      list-style: none;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 70px;

      li {
        display: inline-block;
        margin-left: 0px;
        align-self: stretch;

        & a {
          color: ${colors.black};
          ${type18}
          ${playfairDisplay}
          line-height: 1.3;
          font-weight: bold;
          text-decoration: none;
          padding: 0 10px;
          ${transition}
          height: 100%;
          display: inline-flex;
          align-items: center;
          position: relative;

          &:hover,
          &.active {
            color: ${colors.black};
            background: ${colors.white};
            font-weight: bold;
          }
          &.active::after {
            content: '';
            height: 1px;
            background: ${colors.black};
            width: calc(100% - 20px);
            position: absolute;
            left: 0;
            top: calc(50% + 15px);
            margin: 0 10px;
          }
        }
        ${transition}
      }
    }
  }

  @media screen and (min-width: 1100px) {
    ul {
      margin-right: 35px;
    }
  }
`
