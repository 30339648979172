// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Components
import { nextSVG } from '../../assets/icons'

const NextArrow = ({ onClick }) => (
  <button
    className="arrow next"
    onClick={onClick}
    dangerouslySetInnerHTML={{ __html: nextSVG }}
  />
)

// Components PropTypes
NextArrow.propTypes = {
  onClick: PropTypes.func,
}

export default NextArrow
