// Dependencies
import React, { Component } from "react";
import PropTypes from 'prop-types'

// Styles
import { SocialWrapper } from './Social.styled.js'

class YouTubeSubscribe extends Component {
  static propTypes = {
    channelName: PropTypes.string,
    channelid: PropTypes.string.isRequired,
    theme: PropTypes.string,
    layout: PropTypes.string,
    count: PropTypes.string
  };

  static defaultProps = {
    channelName: "Fernando Marinho",
    channelid: "UCid3MRFpwFXZO0ViAZ4f3PA",
    theme: "dark",
    layout: "default",
    count: "default"
  };

  /**
   *  React.createRef to attach script after mount
   *  Ref) https://reactjs.org/docs/refs-and-the-dom.html
   */

  constructor(props) {
    super(props);
    this.youtubeSubscribeNode = React.createRef();

    // To render components economically w/o repetition
    this.state = {
      initialized: false
    };
  }

  initialized() {
    this.setState({
      initialized: true
    });
  }

  /**
   * 1. Script for API doesn't work in index.html.
   * 2. So You have to make it after components render.
   * 3. Make a script with JavaScript method to work.
   * 4. It is a little slow to show component at first.
   * 5. YouTube API gives you channelId instead channelName
   *    So You don't have to think about channelName when you
   *    need its API.
   */

  componentDidMount() {
    if (this.state.initialized) {
      return;
    }

    // Make <script src="https://apis.google.com/js/platform.js" ></script>
    const youtubescript = document.createElement("script");
    youtubescript.src = "https://apis.google.com/js/platform.js";
    this.youtubeSubscribeNode.current.parentNode.appendChild(youtubescript);
    this.initialized();
  }

  render() {
    const { theme, layout, count, channelName, channelid } = this.props;

    return (
      <div
        ref={this.youtubeSubscribeNode}
        className="g-ytsubscribe"
        data-theme={theme}
        data-layout={layout}
        data-count={count}
        data-channel={channelName}
        data-channelid={channelid}
      />
    );
  }
}



const SocialButtons = ({ links }) => {
  return (
    <SocialWrapper>
      <div className="social-buttons">
        {links.map((link) => {
          let component
          switch (link?.type) {
            case 'facebook':
              component = (
                <div key={`${link?.type}_${link?.url}`} className="fb-like" data-href="https://www.facebook.com/fernandomarinho.violin/" data-width="" data-layout="button" data-action="like" data-size="large" data-share="false" />
              )
              break
            case 'youtube':
              component = (
                <YouTubeSubscribe
                  key={`${link?.type}_${link?.url}`}
                  className="g-ytsubscribe"
                  data-theme={'dark'}
                  data-layout={'default'}
                  data-count={'default'}
                  data-channel={'Fernando Marinho'}
                  data-channelid={'UCid3MRFpwFXZO0ViAZ4f3PA'}
                />
              )
              break
            // case 'soundcloud':
            //   component = (
            //     <iframe key={`${link?.type}_${link?.url}`} allowtransparency="true" scrolling="no" frameBorder="no" src="https://w.soundcloud.com/icon/?url=http%3A%2F%2Fsoundcloud.com%2Ffernandomarinhoviolin&color=orange_white&size=32" style={{ width: '32px', height: '32px' }} />
            //   )
            //   break
            case 'spotify':
              component = (
                <iframe key={`${link?.type}_${link?.url}`} src="https://open.spotify.com/follow/1/?uri=spotify:artist:6sFIWsNpZYqfjUpaCgueju&size=basic&theme=dark&show-count=0" width={92} height={25} scrolling="no" frameBorder={0} style={{ border: 'none', overflow: 'hidden', marginTop: '-4px' }} allowtransparency="true" />
              )
              break
          }
          return component
        })}
      </div>
    </SocialWrapper>
  )
}

// Components PropTypes
SocialButtons.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default SocialButtons
