import { maxWidthValue } from './breakpoints'

export const maxWidth = `
  max-width: ${maxWidthValue};
  width: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
`

export const transition = `
    transition: all 0.2s ease-in-out;
`
